/* eslint-disable operator-linebreak */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { Tabs, Form, Button, message, Input, Row, Col, Card, Upload, Select } from 'antd';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { LoadingOutlined } from '@ant-design/icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PageHeaderAlt } from '../../../../../../components/layout-components/PageHeaderAlt';
import Flex from '../../../../../../components/shared-components/Flex';
import { ImageSvg } from '../../../../../../assets/svg/icon';
import CustomIcon from '../../../../../../components/util-components/CustomIcon';
import {
  uploadFile,
  getItemsByConditionProduct,
  updateItem,
  getItems,
  getItemsByCondition,
  getItemsByConditionGuest,
  createItemCustom,
} from '../../../../../../api/api';
import { useAuth } from '../../../../../../context/authContext';
import LogInventory from './logInventory';
import Delivery from '../../../../../../components/Delivery';

// DRAG AND OPTION INVENTORY
const { Dragger } = Upload;
const { Option } = Select;
const legthUnit = ['bottles', 'servings'];

// GENERAL RULES
const rules = {};

// IMG GENERAL

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

function ProductForm() {
  // GENERAL STATE
  const { user, idMenu, idCategory } = useAuth();
  const paramIdRestaurant = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [menu, setMenu] = useState(null);
  const [category, setCategory] = useState(null);
  const [menuSelect, setMenuSelect] = useState(idMenu?.id);
  const [menuSelectName, setMenuSelectName] = useState(idMenu?.name);
  const [oldMenuSelectName, setOldMenuSelectName] = useState(idMenu?.name);
  const [categorySelect, setCategorySelect] = useState(idCategory?.id);
  const [categorySelectName, setCategorySelectName] = useState(idCategory?.name);
  const [oldCategorySelectName, setOldCategorySelectName] = useState(idCategory?.name);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const matches = useMediaQuery('(max-width: 699px)');
  const [nameImg, setNameImg] = useState();
  const [dataImg, setDataImg] = useState();
  const [preview, setPreview] = useState();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [themeInputs, setThemeInputs] = useState();
  const [themeCell, setThemeCell] = useState();
  const { headerNavColor, currentTheme } = useSelector((state) => state.theme);
  const isDarkTheme = currentTheme === 'dark';
  // INVENTORY STATE
  const [selectInventory, setSelectInventory] = useState(false);
  const [typeQuantity, setTypeQuantity] = useState(product?.inventory?.[0]?.typeBottles);
  const [numbBottles, setNumbBottles] = useState();

  // DELIVERY STATES
  const [productData, setProductData] = useState({});
  const [deliveryShow, setDeliveryShow] = useState(false);

  // eslint-disable-next-line consistent-return
  const getRestaurantData = async () => {
    if (paramIdRestaurant) {
      const data = await getItems('restaurants');
      const filterRestaurant = data.filter((item) => item.id === paramIdRestaurant.idRestaurant);

      if (filterRestaurant && filterRestaurant[0]) {
        return {
          restaurantId: filterRestaurant[0].id,
          restaurantName: filterRestaurant[0].restaurantName,
        };
      }
    }
  };

  // ALL FILE COLOR
  const colorText = useMemo(() => {
    if (isDarkTheme) {
      setThemeInputs('#1b2531');
      setThemeCell('rgb(0 0 0)');
      return '#fff';
    }
    setThemeCell('rgb(148 152 163)');
    setThemeInputs('#fafafb');
    return '#455560';
  }, [isDarkTheme]);

  // GET DATA FOR VIEW
  const dataMenu = async () => {
    setLoading(true);
    const data = await getItemsByConditionProduct(
      paramIdRestaurant?.idCategory,
      'products',
      'categoryId',
    );
    const filterData = data?.filter((item) => item?.id === paramIdRestaurant?.id);
    const [newObjet] = filterData;
    setNumbBottles(newObjet?.inventory?.[0]?.quantity);
    setProduct(newObjet);

    const { restaurantName } = await getRestaurantData();

    setProductData({
      productId: paramIdRestaurant.id,
      restaurantId: paramIdRestaurant.idRestaurant,
      restaurantName,
      image: newObjet?.image,
      telephoneNumber: newObjet?.telephoneNumber,
      name: newObjet?.name,
    });

    setLoading(false);
  };

  // INIT STATE
  useEffect(() => {
    dataMenu();
  }, [preview]);

  // EFFECT FROM GENERAL IMG
  useEffect(() => {
    setPreview(undefined);
    if (!selectedFile) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const bucketImageProduct = (data) => {
    setUploadLoading(true);
    if (!data) {
      setSelectedFile(undefined);
      setUploadLoading(false);
      return;
    }
    setUploadLoading(false);
    setSelectedFile(data?.originFileObj);

    setDataImg(data?.originFileObj);
    setNameImg(data?.originFileObj?.name);
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');

    // Aplica el formato (123) 456-7890
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  }

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    form.setFieldsValue({ telephoneNumber: formattedPhoneNumber });
  };

  const onFinish = async () => {
    try {
      setSubmitLoading(true);
      const values = form.getFieldValue();

      const matchedValues = {};

      for (const key of Object.keys(values)) {
        if (product?.hasOwnProperty(key)) {
          matchedValues[key] = product[key];
        } else if (product?.hasOwnProperty('inventory') && Array.isArray(product?.inventory)) {
          for (const item of product.inventory) {
            if (item?.hasOwnProperty(key)) {
              matchedValues[key] = item[key];
              break;
            }
          }
        }
      }

      const time = Timestamp.fromDate(new Date()).toDate();
      const sendUrlImg = await uploadFile(dataImg, nameImg);

      const sumBarBottles =
        (parseFloat(values?.quantityBar1 ?? product?.inventory?.[0]?.quantityBar1) || 0) +
        (parseFloat(values?.quantityBar2 ?? product?.inventory?.[0]?.quantityBar2) || 0) +
        (parseFloat(values?.quantityBar3 ?? product?.inventory?.[0]?.quantityBar3) || 0);

      const totalCages =
        (parseFloat(values?.quantityCagesBar1 ?? product?.inventory?.[0]?.quantityCagesBar1) || 0) +
        (parseFloat(values?.quantityCagesBar2 ?? product?.inventory?.[0]?.quantityCagesBar2) || 0) +
        (parseFloat(values?.quantityCagesBar3 ?? product?.inventory?.[0]?.quantityCagesBar3) || 0);

      // eslint-disable-next-line no-unsafe-optional-chaining
      const totalBottles = sumBarBottles + (totalCages * (values?.quantityForCages ?? product?.inventory?.[0]?.quantityCagesBar3) || 0);

      const obj = {
        name: values?.name ?? product?.name,
        description:
          values?.description || values?.description === ''
            ? values?.description
            : product?.description,
        price: values?.price ?? product?.price,
        categoryId: categorySelect ?? paramIdRestaurant?.idCategory,
        image: dataImg && nameImg ? sendUrlImg : product?.image,
        keyword: values?.name?.toLowerCase() || product?.keyword,
        menuId: menuSelect ?? paramIdRestaurant?.idMenu,
        restaurantId: paramIdRestaurant?.idRestaurant,
        views: product?.views,
        abv: values?.abv ?? (product?.abv || ''),
        body: values?.body ?? (product?.body || ''),
        brand: values?.brand ?? (product?.brand || ''),
        countryState: values?.countryState ?? (product?.countryState || ''),
        region: values?.region ?? (product?.region || ''),
        sku: values?.sku ?? (product?.sku || ''),
        taste: values?.taste ?? (product?.taste || ''),
        type: values?.type ?? (product?.type || ''),
        varietal: values?.varietal ?? (product?.varietal || ''),
        deleted: product?.deleted,
        enabled: product?.enabled,
        position: product?.position,
        positionInCategory: product?.positionInCategory ?? (product?.position || 0),
        par: values?.par ?? (product?.par || ''),
        purchaseCost: values?.purchaseCost ?? (product?.purchaseCost || 0),
        servings: values?.servings ?? (product?.servings || ''),
        upc: values?.upc ?? (product?.upc || ''),
        weight: values?.weight ?? (product?.weight || ''),
        quantityPerCage: values?.quantityPerCage ?? (product?.quantityPerCage || ''),
        telephoneNumber: values?.telephoneNumber ?? (product?.telephoneNumber || ''),
        inventory: [
          {
            quantity: totalBottles ?? product?.inventory?.[0]?.quantity,
            quantityBar1: values?.quantityBar1 ?? (product?.inventory?.[0]?.quantityBar1 || ''),
            quantityBar2: values?.quantityBar2 ?? (product?.inventory?.[0]?.quantityBar2 || ''),
            quantityBar3: values?.quantityBar3 ?? (product?.inventory?.[0]?.quantityBar3 || ''),
            quantityCagesBar1:
              (values?.quantityCagesBar1 ?? product?.inventory?.[0]?.quantityCagesBar1) || '',
            quantityCagesBar2:
              (values?.quantityCagesBar2 ?? product?.inventory?.[0]?.quantityCagesBar2) || '',
            quantityCagesBar3:
              (values?.quantityCagesBar3 ?? product?.inventory?.[0]?.quantityCagesBar3) || '',
            quantityForCages: totalCages ?? (product?.inventory?.[0]?.quantityForCages || ''),
          },
        ],
      };

      console.log('🚀 ~ obj:> ', obj);

      const oldObjLog = {
        ...matchedValues,
        menu: oldMenuSelectName,
        category: oldCategorySelectName,
      };
      const editObjLog = {
        ...values,
        menu: menuSelectName,
        category: oldCategorySelectName,
      };

      const logObjet = {
        idRestaurant: paramIdRestaurant?.idRestaurant,
        log: [
          {
            user: user?.displayName,
            email: user?.email,
            type: 'edit product',
            time,
            nameItem: values?.name || product?.name,
            edit: editObjLog,
            oldValues: oldObjLog,
          },
        ],
      };
      await createItemCustom(logObjet, 'log');

      const logObjetSave = {
        idRestaurant: paramIdRestaurant?.idRestaurant,
        log: [
          {
            user: user?.displayName,
            email: user?.email,
            type: 'edit web',
            time,
            nameItem: values?.name || product?.name,
            idItem: paramIdRestaurant?.id,
            newData: [
              {
                typeBottles: typeQuantity || product?.inventory?.[0]?.typeBottles || '',
                quantity: numbBottles || '',
              },
            ],
            oldData: [
              {
                typeBottles: product?.inventory?.[0]?.typeBottles || '',
                quantity: product?.inventory?.[0]?.quantity || '',
              },
            ],
          },
        ],
      };
      await createItemCustom(logObjetSave, 'logInventory');

      await updateItem(paramIdRestaurant?.id, obj, 'products');
      setSubmitLoading(false);
      message.success('Changes saved');
      navigate(-1);
    } catch (error) {
      console.log('ERROR: ', error);
      setSubmitLoading(false);
      message.error('Error saving changes');
    }
  };

  const handleImg = () => {
    setDataImg();
    setNameImg();
  };

  const onBack = () => {
    navigate(-1);
  };

  const dataMenuProduct = async () => {
    const data = await getItemsByCondition(user.uid, 'menus', 'restaurantId');
    setMenu(data);
  };

  const dataCategory = async (menuSelectprops) => {
    const data = await getItemsByCondition(menuSelectprops || idMenu?.id, 'categories', 'menuId');
    setCategory(data);
  };

  useEffect(() => {
    dataMenuProduct();
    dataCategory(menuSelect);
  }, [menuSelect]);

  const changeSelectMenu = (id, name) => {
    setMenuSelect(id);
    setMenuSelectName(name?.children);
  };

  const changeSelectCategory = (id, name) => {
    setCategorySelect(id);
    setCategorySelectName(name?.children);
  };

  // INENTORY

  const handleChangeValueBottles = (value) => {
    setSelectInventory(true);
    setNumbBottles(value?.target?.value);
  };
  const handleChangeSelectBottles = (value) => {
    if (typeQuantity || product?.inventory?.[0]?.typeBottles !== value) {
      setSelectInventory(true);
    }
    setTypeQuantity(value);
  };

  // DELIVERY

  const showDelivery = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e === '4' ? setDeliveryShow(true) : setDeliveryShow(false);
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
      >
        <PageHeaderAlt className="border-bottom" overlap>
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="space-between"
              alignItems="center"
              flexDirection={matches ? 'column' : 'row'}
            >
              <h2 className="mb-3" style={{ color: colorText }}>
                {`Edit Product: ${product?.name}`}
              </h2>
              <div className="mb-3">
                <Button className="mr-2" onClick={() => onBack()}>
                  Discard
                </Button>
                <Button
                  type="primary"
                  onClick={() => onFinish()}
                  htmlType="submit"
                  loading={submitLoading}
                >
                  Save
                </Button>
              </div>
            </Flex>
          </div>
        </PageHeaderAlt>
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              left: 0,
              right: 0,
              top: '50%',
            }}
          >
            <CircularProgress
              style={{ color: '#5E4090', width: '5%', height: '5%' }}
              thickness={1}
            />
          </div>
        )}
        {!loading && (
          <div className="container">
            <Tabs
              defaultActiveKey="1"
              onChange={(e) => showDelivery(e)}
              style={{ marginTop: 60 }}
              items={[
                {
                  label: 'General',
                  key: '1',
                  children: (
                    <Row gutter={16}>
                      <Col xs={24} sm={24} md={17}>
                        <Card title="Basic Info">
                          <Form.Item name="name" label="Product name">
                            <Input placeholder="Product Name" defaultValue={product?.name} />
                          </Form.Item>
                          <Form.Item name="description" label="Description">
                            <Input.TextArea rows={4} defaultValue={product?.description} />
                          </Form.Item>
                          <Col xs={24} sm={24} md={12}>
                            <Form.Item name="price" label="Price">
                              <Input
                                type="number"
                                className="w-100"
                                formatter={(value) =>
                                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                defaultValue={product?.price}
                              />
                            </Form.Item>
                          </Col>
                        </Card>
                        <Card title="Additional info">
                          <Row gutter={16}>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="abv" label="ABV">
                                <Input className="w-100" value={0} defaultValue={product?.abv} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="body" label="Body">
                                <Input className="w-100" defaultValue={product?.body} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="brand" label="Brand">
                                <Input
                                  className="w-100"
                                  min={0}
                                  max={100}
                                  defaultValue={product?.brand}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="countryState" label="Country-State">
                                <Input
                                  className="w-100"
                                  min={0}
                                  max={100}
                                  defaultValue={product?.countryState}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="region" label="Region">
                                <Input
                                  className="w-100"
                                  min={0}
                                  max={100}
                                  defaultValue={product?.region}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="sku" label="Sku">
                                <Input
                                  className="w-100"
                                  min={0}
                                  max={100}
                                  defaultValue={product?.sku}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="taste" label="Taste">
                                <Input
                                  className="w-100"
                                  min={0}
                                  max={100}
                                  defaultValue={product?.taste}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="type" label="Type">
                                <Input
                                  className="w-100"
                                  min={0}
                                  max={100}
                                  defaultValue={product?.type}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item name="varietal" label="Varietal">
                                <Input
                                  className="w-100"
                                  min={0}
                                  max={100}
                                  defaultValue={product?.varietal}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                name="telephoneNumber"
                                label="Supplier number of this product"
                                rules={[
                                  {
                                    pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                    message:
                                      'The phone number is not valid, it must have a phone number format, e.g.: (123) 456-7890',
                                  },
                                ]}
                              >
                                <Input
                                  className="w-100"
                                  onChange={handlePhoneNumberChange}
                                  defaultValue={product?.telephoneNumber}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col xs={24} sm={24} md={7}>
                        <Card title="Media">
                          <Dragger
                            beforeUpload={beforeUpload}
                            onClick={handleImg}
                            onChange={(e) => bucketImageProduct(e?.file, e?.file?.name, e)}
                          >
                            {product ? (
                              <img
                                src={preview || product?.image}
                                alt="avatar"
                                className="img-fluid"
                              />
                            ) : (
                              <div>
                                {uploadLoading ? (
                                  <div>
                                    <LoadingOutlined className="font-size-xxl text-primary" />
                                    <div className="mt-3">Uploading</div>
                                  </div>
                                ) : (
                                  <div>
                                    <CustomIcon
                                      className="display-3"
                                      svg={preview || product?.image || ImageSvg}
                                    />
                                    <p>Click or drag file to upload</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </Dragger>
                        </Card>
                        <Card title="Organization">
                          <p>{`Current menu: ${oldMenuSelectName}`}</p>
                          <Select
                            className="w-100"
                            placeholder="menu"
                            onChange={(id, name) => changeSelectMenu(id, name)}
                            style={{ marginBottom: '2rem' }}
                          >
                            {menu?.map((elm) => (
                              <Option
                                defaultValue={menuSelect}
                                key={elm?.id}
                                style={{
                                  color: menuSelect === elm?.id ? '#F2CD5C' : '#000',
                                }}
                              >
                                {elm?.name}
                              </Option>
                            ))}
                          </Select>
                          <p>{`Current category: ${oldCategorySelectName}`}</p>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="categorys"
                            onChange={(id, name) => changeSelectCategory(id, name)}
                          >
                            {category?.map((elm) => (
                              <Option
                                defaultValue={categorySelect}
                                key={elm?.id}
                                style={{
                                  color: categorySelect === elm?.id ? '#F2CD5C' : '#000',
                                }}
                              >
                                {elm?.name}
                              </Option>
                            ))}
                          </Select>
                        </Card>
                        <Card title="Units">
                          <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <Form.Item name="quantityBar1" label="Bottles in Bar 1">
                                <Input
                                  type="number"
                                  className="w-100"
                                  formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  defaultValue={product?.inventory?.[0]?.quantityBar1}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <Form.Item name="quantityCagesBar1" label="Cages in Bar 1">
                                <Input
                                  type="number"
                                  className="w-100"
                                  formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  defaultValue={product?.inventory?.[0]?.quantityCagesBar1}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <Form.Item name="quantityBar2" label="Bottles in Bar 2">
                                <Input
                                  type="number"
                                  className="w-100"
                                  formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  defaultValue={product?.inventory?.[0]?.quantityBar2}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <Form.Item name="quantityCagesBar2" label="Cages in Bar 2">
                                <Input
                                  type="number"
                                  className="w-100"
                                  formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  defaultValue={product?.inventory?.[0]?.quantityCagesBar2}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <Form.Item name="quantityBar3" label="Bottles in Bar 3">
                                <Input
                                  type="number"
                                  className="w-100"
                                  formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  defaultValue={product?.inventory?.[0]?.quantityBar3}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12}>
                              <Form.Item name="quantityCagesBar3" label="Cages in Bar 3">
                                <Input
                                  type="number"
                                  className="w-100"
                                  formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  defaultValue={product?.inventory?.[0]?.quantityCagesBar3}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  ),
                },
                {
                  /* INVENTORY */
                },
                {
                  label: 'Inventory',
                  key: '3',
                  children: (
                    <Card title="Inventory">
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="purchaseCost" label="Purchase cost">
                            <Input
                              className="w-100"
                              value={0}
                              defaultValue={product?.purchaseCost}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="servings" label="Servings">
                            <Input className="w-100" value={0} defaultValue={product?.servings} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="upc" label="UPC">
                            <Input className="w-100" value={0} defaultValue={product?.upc} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="weight" label="Weight">
                            <Input className="w-100" value={0} defaultValue={product?.weight} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="par" label="PAR">
                            <Input
                              className="w-100"
                              min={0}
                              max={100}
                              defaultValue={product?.par}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="quantityPerCage" label="Quantity per Cages">
                            <Input
                              className="w-100"
                              min={0}
                              max={100}
                              defaultValue={product?.quantityPerCage}
                            />
                          </Form.Item>
                        </Col>
                        <LogInventory />
                      </Row>
                    </Card>
                  ),
                },
                {
                  label: 'Delivery',
                  key: '4',
                },
              ]}
            />
          </div>
        )}
      </Form>
      <div className="container">{deliveryShow && <Delivery productData={productData} />}</div>
    </div>
  );
}

export default ProductForm;
